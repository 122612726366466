define('webshopProductsListFiltersView',[
  'app',
  'module',
  'backbone',
  'underscore',
  'settings',
  'moment',
  'webshopProductsListFiltersEditView',
  'filtersBannerView',
  'entities/webshop/products'
], function(
  App,
  Module,
  Backbone,
  _,
  Settings,
  moment,
  EditView,
  FiltersBannerView
) {
  'use strict';
  
  Module.exports = FiltersBannerView.extend({
    editViewClass: EditView,

    initialize: function() {
      this.tableView = this.options.tableView;
      var settings = Settings.get('webshopProductsListFilters', true);

      var content = {
        code: '',
        name: '',
        status: '',
        tag: '',
        dateFrom: '',
        dateTo: '',
        category: '',
        outdated: '',
        unfinished: ''
      };

      if(settings) {
        var data = _.extend(content, JSON.parse(settings));
        this.model = new Backbone.Model(data);
      } else {
        this.model = new Backbone.Model(content);
        Settings.set('webshopProductsListFilters', JSON.stringify(this.model.attributes), true);
      }
      this.tableView.filters = this.model;

      FiltersBannerView.prototype.initialize.apply(this, arguments);
    },

    setCollection: function() {
      if(this.model.get('status') !== '') {
        var status = '';
        if(this.model.get('status') === '0')
          status = _.i18n('common.disabled');
        if(this.model.get('status') === '1')
          status = _.i18n('common.enabled');

        this.collection.add(new Backbone.Model({
          id: 'status',
          libelle: _.i18n('common.status'),
          value: status
        }));
      }

      //----------------
      if (Settings.configValue('webshop.products.showUnfinished')) {
        if (this.model.get('unfinished') !== '') {
          var unfinished = '';
          if (this.model.get('unfinished') === '0')
            unfinished = _.i18n('common.no');
          if (this.model.get('unfinished') === '1')
            unfinished = _.i18n('common.yes');

          this.collection.add(new Backbone.Model({
            id: 'unfinished',
            libelle: _.i18n('webshop.products.unfinished'),
            value: unfinished
          }));
        }
      }

      //----------------
      if(this.model.get('tag') !== '') {
        var tagValue = '';
        App.request('webshop.products.tags').done(_.bind(function (tags) {
          _.each(tags.models, _.bind(function (tag) {
            if(tag.get('id') === this.model.get('tag'))
              tagValue = _.i18n('webshop.product.tag.' + tag.get('code'));
          }, this));
          this.collection.add(new Backbone.Model({
            id: 'tag',
            libelle: _.i18n('webshop.product.tag'),
            value: tagValue
          }));
        }, this));
      }

      //----------------
      var dateValue = '';

      if(this.model.get('dateFrom') !== '' && this.model.get('dateTo') !== '') {
        dateValue = moment(new Date(this.model.get('dateFrom'))).format('DD/MM/YYYY');
        dateValue += ' - ';
        dateValue += moment(new Date(this.model.get('dateTo'))).format('DD/MM/YYYY');
        this.collection.add(new Backbone.Model({
          id: 'dates',
          libelle: _.i18n('common.dates'),
          value: dateValue
        }));
      }

      //----------------
      if(this.model.get('category') !== '') {
        App.request('webshop.categories.datalist').done(_.bind(function (categories) {
          _.each(categories, _.bind(function (category) {
            if(category.get('id') === this.model.get('category'))
              this.collection.add(new Backbone.Model({
                id: 'category',
                libelle: _.i18n('common.category'),
                value: category.getName()
              }));
          }, this));
        }, this));
      }

      //----------------
      if(this.model.get('code') !== '') {
        this.collection.add(new Backbone.Model({
          id: 'code',
          libelle: _.i18n('common.code'),
          value: this.model.get('code')
        }));
      }

      //----------------
      if(this.model.get('name') !== '') {
        this.collection.add(new Backbone.Model({
          id: 'name',
          libelle: _.i18n('common.name'),
          value: this.model.get('name')
        }));
      }

      //----------------
      if(this.model.get('outdated') === true) {
        this.collection.add(new Backbone.Model({
          id: 'outdated',
          libelle: _.i18n('webshop.product.outdated'),
          value: _.i18n('common.yes')
        }));
      }

      //----------------
      FiltersBannerView.prototype.setCollection.apply(this, arguments);
    },

    onDeleteFilter: function(model, filterModel) {
      if(filterModel.get('id') === 'dates') {
        model.set('dateFrom', '');
        model.set('dateTo', '');
      } else
        model.set(filterModel.get('id'), '');
    },

    fetch: function(model) {
      var needFetch = false;

      if(this.model.get('dateFrom') !== model.get('dateFrom') ||
          this.model.get('dateTo') !== model.get('dateTo'))
        needFetch = true;

      if(this.model.get('code') !== model.get('code'))
        needFetch = true;

      if(this.model.get('name') !== model.get('name'))
        needFetch = true;

      if(this.model.get('status') !== model.get('status'))
        needFetch = true;

      if(this.model.get('tag') !== model.get('tag'))
        needFetch = true;

      if(this.model.get('category') !== model.get('category'))
        needFetch = true;

      if(this.model.get('outdated') !== model.get('outdated'))
        needFetch = true;

      if (this.model.get('unfnished') !== model.get('unfinished'))
        needFetch = true;

      FiltersBannerView.prototype.fetch.apply(this, arguments);
      Settings.set('webshopProductsListFilters', JSON.stringify(this.model.attributes), true);

      if(needFetch) {
        this.tableView.footerView.setCurrentPage(1);
        this.tableView.bodyView.fetch();
        this.tableView.footerView.fetch();
      }
    }
  });  
});

