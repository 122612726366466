
define('template!ordersDetailOrderClientImageView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="picture-elements">\n    <img>\n    ';
 if (imageUid) { ;
__p += '\n    <div class="picture-editor">\n        <i class="mdi-image-color-lens icon js-placePA"\n           data-uid="' +
((__t = ( imageUid )) == null ? '' : __t) +
'"\n           data-productId="' +
((__t = ( productId )) == null ? '' : __t) +
'"\n           data-variationId="' +
((__t = ( variationId )) == null ? '' : __t) +
'">\n\n        </i>\n    </div>\n    ';
 } ;
__p += '\n</div>';

}
return __p
};});

