define('webshopCategoriesDetailBodyProductsHeaderToolsView',[
  'app',
  'module',
  'underscore',
  'buttonView',
  'tableHeaderFieldView',
  'webshopProductsFinderView',
  'template!webshopCategoriesDetailBodyProductsHeaderToolsView'
], function (
  App,
  Module,  
  _,
  ButtonView,
  FieldView,
  ProductFinderView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'webshop-categories-detail-body-products-header-tools-view ' + FieldView.prototype.className,

    ui: {
      inputUpload: '.upload-input',
      test: '.test'  // Do not remove, if removed, inputUpload change event will not work
    },

    regions: {
      addRegion: '.add-region',
      importRegion: '.import-region',
      exportPositionRegion: '.export-position-region'
    },

    events: {
      'change @ui.inputUpload': 'onImport',
      'click @ui.test': 'onClickTest' // Do not remove, if removed, inputUpload change event will not work
    },

    onRender: function() {
      this.addButtonView = new ButtonView({
        type: 'add',
        text: _.i18n('products.add'),
        title: _.i18n('products.add')
      });

      this.addRegion.show(this.addButtonView);
      this.addButtonView.on('click', _.bind(this.onAdd, this));

      //------------------
      this.importButtonView = new ButtonView({
        type: 'upload'
      });

      this.importRegion.show(this.importButtonView);
      this.importButtonView.listenTo(this.importButtonView, 'click', _.bind(this.onSelectImportFile, this));

      //------------------
      this.exportPositionButtonView = new ButtonView({
        type: 'download'
      });

      this.exportPositionRegion.show(this.exportPositionButtonView);
      this.exportPositionButtonView.listenTo(this.exportPositionButtonView, 'click', _.bind(this.onExportPosition, this));
    },

    onAdd: function() {
      ProductFinderView.prototype.open({
        title: _.i18n('wehshop.product.categories.add'),
        onSelected: _.bind(function (products) {
          var ids = [];
          _.each(products, function (product) {
              ids.push(product.getId());
          });
          this.tableView.model.addProducts(ids).done(_.bind(function () {
            this.tableView.reload();
            this.tableView.footerView.fetch();
          }, this));
        }, this)
      });
    },

    onSelectImportFile: function() {
      this.ui.inputUpload.trigger('click');
    },

    onExportPosition: function() {
      if(this.fetchProductsPosition)
        this.fetchProductsPosition.abort();

      this.fetchProductsPosition = $.ajax({
        type: 'GET',
        url: App.request('webshop.category.products.positions', this.options.tableView.model.get('id')),
        success: _.bind(function (positions) {
          var csv = '';
          _.each(positions, function (position) {
            csv += position.code + ',' + position.position + '\n';
          });

          var blob = new Blob([csv], {type: 'text/csv'});
          var url = URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = 'products.csv';
          a.click();
        }, this)
      });
    },

    onImport: function(e) {
      var target = e.target;

      if (target && target.files && target.files[0]) {
        var reader;
        reader = new FileReader();

        this.tableView.footerView.loader(true);

        reader.onload = _.bind(function () {
          var products = [];
          try {
            var items = reader.result.split('\n');
            _.each(items, function (item) {
              var values = item.split(',');
              if(values.length === 2 && values[0] !== '' && values[1] !== '') {
                var product = {
                  code: values[0],
                  position: values[1]
                };
                products.push(product);
              }
            }, this);
          }
          catch (e) {}


          this.tableView.model.addProductList(products).done(_.bind(function () {
            this.tableView.reload();
            this.tableView.footerView.fetch();
          }, this));
        }, this);
        reader.onerror = function () {

        };
        reader.readAsText(target.files[0]);
      }
    },

    onClick: function() {
    }
  });
});
