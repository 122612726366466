define('ordersBoardGroupView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'moment',
  'settings',
  'rolesMixin',
  'usersView',
  'ordersLabelsView',
  'ordersDeliveryView',
  'ordersBoardGroupCardsView',
  'ordersController',
  'template!ordersBoardGroupView',
  'entities/orders'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  moment,
  Settings,
  RolesMixin,
  UsersView,
  LabelsView,
  DeliveryView,
  CardsView,
  Controller,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'orders-board-kanban-cards-group-view',
    type: 'group',

    ui: {
      group: '.group',
      date: '.header .date',
      clientName: '.client-name .text',
      country: '.country',
      sameOrder: '.same-order',
      sameOrderCount: '.same-order .count',
      vip: '.client-name .vip',
      price: '.price',
      minimizeButton: '.minimize-button',
      minimizeCount: '.minimize-button > .count',
      messages: '.footer .messages',
      ukrMessages: '.footer .ukr-messages'
    },

    events: {
      'click': 'onSelect',
      'dblclick @ui.group': 'openCustomer',
      'click @ui.minimizeButton': 'changeMinimized',
      'mousedown': 'onMouseDown'
    },

    regions: {
      usersRegion: '.users-region',
      labelsRegion: '.labels-region',
      cardsRegion: '.cards-region',
      deliveryRegion: '.group-delivery-region'
    },

    modelEvents: {
      'change:orders': 'fetch',
      'change:companyName': 'renderName',
      'change:contactPerson': 'renderName',
      'change:endUserId': 'renderName',
      'change:priority': 'renderPriority',
      'change:minimized': 'renderMinimized'
    },

    onShow: function () {
      this.usersModel = new Backbone.Model({userpids: []});
      this.usersView = new UsersView({
        model: this.usersModel,
        onSelected: _.bind(function (user) {
          var deferred = new $.Deferred();
          _.each(this.model.get('orders').models, function (model) {
            model.assignUser(user.get('secId')).done(_.bind(function () {
              model.fetch();
            }, this));
          }, this);
          deferred.resolve();
          return deferred;
        }, this),
        onUnSelected: _.bind(function (user) {
          var deferred = new $.Deferred();
          _.each(this.model.get('orders').models, function (model) {
            model.unassignUser(user.get('secId')).done(_.bind(function () {
              model.fetch();
              deferred.resolve();
            }, this));
          }, this);
          deferred.resolve();
          return deferred;
        }, this)
      });
      this.getRegion('usersRegion').show(this.usersView);

      //--------------------
      this.labelsView = new LabelsView({
        model: this.model,
        readOnly: true
      });
      this.getRegion('labelsRegion').show(this.labelsView);

      //--------------------
      this.cardsView = new CardsView({
        status: this.options.status,
        kanbanView: this.options.kanbanView,
        cards: this.options.cards,
        boardView: this.options.boardView,
        collection: this.model.get('orders'),
        groupView: this,
        mailParams: this.options.mailParams
      });
      this.getRegion('cardsRegion').show(this.cardsView);
      this.cardsView.on('change:cardColor', this.renderLabels, this);
      this.cardsView.on('change:priority', this.renderPriority, this);
      this.cardsView.on('change:userpids', this.renderUsers, this);
      this.cardsView.on('change:price', this.fetch, this);
      this.cardsView.on('change:nbrPackSentToPrinter', this.renderDelivery, this);
      this.cardsView.on('change:nbrPackDeliveredToPrinter', this.renderDelivery, this);
      this.cardsView.on('change:nbrPackSent', this.renderDelivery, this);
      this.cardsView.on('change:nbrPackDelivered', this.renderDelivery, this);
      this.cardsView.on('change:expShippingDate', this.renderDelivery, this);
      this.cardsView.on('change:deadlineDate', this.renderDelivery, this);
      this.cardsView.on('change:actionRequiredInternal', this.renderMessages, this);
      this.cardsView.on('change:actionRequiredUkr', this.renderMessages, this);
      this.cardsView.on('onDrag', function (view) {
        this.trigger('onDrag', view);
      }, this);
      this.cardsView.on('onStopDrag', function (view) {
        this.trigger('onStopDrag', view);
      }, this);
      this.cardsView.on('change:customer', this.renderVip, this);

      //--------------------
      this.deliveryView = new DeliveryView({
        model: this.model,
        readOnly: true
      });
      this.getRegion('deliveryRegion').show(this.deliveryView);

      //--------------------
      this.fetch();
    },

    onRender: function () {
      //-------------------
      if(!Settings.configValue('test.hideBoardContextMenu')) {
        this.$el.contextMenu({
          selector: '.group',
          build: _.bind(function () {
            return {
              items: Controller.contextMenu(
                  this.options.type,
                  this.options.kanbanView.getSelectedOrders(),
                  this.options.kanbanView.lastSelected,
                  _.bind(function() {
                    this.unSelectAll();
                  }, this))
            };
          }, this)
        });
      }
    },

    renderPriority: function () {
      var priority = 0;
      _.each(this.cardsView.collection.models, function (model) {
        if(model.get('priority') > priority)
          priority = model.get('priority');
      }, this);

      this.model.set('priority', priority);

      this.$el.attr('data-priority', this.model.get('priority'));
    },

    renderName: function () {
      var clientName = '';
      if(this.order)
        clientName = this.order.getClientName();

      this.ui.clientName.html(clientName);
      this.ui.clientName.attr('title', clientName);
    },

    renderDate: function () {
      if(this.model.get('createDate')) {
        if (Settings.configValue('board.card.fullDate') === true)
          this.ui.date.html(this.model.get('createDate').format('DD.MM.YY HH:mm'));
        else {
          var today = moment();
          if (this.model.get('createDate').isSame(today, 'day'))
            this.ui.date.html(this.model.get('createDate').format('HH:mm'));
          else
            this.ui.date.html(this.model.get('createDate').format('DD.MM.YY'));
        }
      }
    },

    renderCountry: function () {
      var country = '';
      if(this.order && this.order.get('webShopLocale'))
        country = this.order.get('webShopLocale');

      if (country !== '') {
        this.ui.country.css('display', 'flex');
        this.ui.country.html(country);
      }
      else
        this.ui.country.hide();
    },

    renderSameOrder: function () {
      var sameOrderCount = 0;
      if(this.order)
        sameOrderCount = this.order.get('nbrOrderSameCustomer');

      if (sameOrderCount > 0) {
        this.ui.sameOrder.addClass('open');
        this.ui.sameOrderCount.html(sameOrderCount);
      } else {
        this.ui.sameOrder.removeClass('open');
      }
    },

    renderPrice: function () {
      var locale = 'fr-BE';

      var configPriceLocale = Settings.configValue('priceLocale');
      if (configPriceLocale)
        locale = configPriceLocale;

      var sPrice = new Intl.NumberFormat(locale,
          {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(this.model.get('price'));

      var html = '';
      if (sPrice)
        html = '€ ' + sPrice;

      if (this.model.get('buyingPrice') && this.model.get('price')) {
        html += ' - ';
        var margin = Math.round(((this.model.get('price') - this.model.get('buyingPrice')) / this.model.get('price')) * 100);
        if (margin < 20) {
          html += '<span style="color:red;">' + margin + '%' + '</span>';
        } else {
          html += margin + '%';
        }
      }

      this.ui.price.html(html);
    },

    renderUsers: function () {
      var userPids = [];
      _.each(this.cardsView.collection.models, function (model) {
        _.each(model.get('userpids'), function (userPid) {
          var found = false;
          _.each(userPids, function (up) {
            if(up.secId === userPid.secId)
              found = true;
          }, this);
          if(!found)
            userPids.push(userPid);
        });
      }, this);

      this.usersModel.set('userpids', userPids);
    },

    renderMinimized: function () {
      if(this.model.get('minimized')) {
        this.$el.addClass('minimized');
        this.ui.minimizeButton.addClass('minimized');
      }
      else {
        this.$el.removeClass('minimized');
        this.ui.minimizeButton.removeClass('minimized');
      }

      this.ui.minimizeCount.html(this.cardsView.collection.length);
    },

    renderLabels: function () {
      var cardColor = [];
      _.each(this.cardsView.collection.models, function (model) {
        var modelCardColorStr = model.get('cardColor');
        if(modelCardColorStr) {
          var modelCardColor = modelCardColorStr.split(',');
          cardColor = _.union(cardColor, modelCardColor);
        }
      }, this);

      this.model.set('cardColor', cardColor.join(','));
    },

    renderDelivery: function () {
      var selectedModel = null;
      var selectedDate = null;

      _.each(this.cardsView.collection.models, function (model) {
        var date = null;

        if (model.get('expShippingDate')) {
          if (typeof (this.model.get('expShippingDate')) === 'object')
            date = model.get('expShippingDate');
          else
            date = new Date(model.get('expShippingDate'));
        }

        if (model.get('deadlineDate')) {
          if (typeof (model.get('deadlineDate')) === 'object')
            date = model.get('deadlineDate');
          else
            date = new Date(model.get('deadlineDate'));
        }
        if(date !== null) {
          if (selectedDate === null || date < selectedDate) {
            selectedDate = date;
            selectedModel = model;
          }
        }
      }, this);

      if(selectedModel) {
        this.model.set('nbrPackSentToPrinter', selectedModel.get('nbrPackSentToPrinter'), {silent: true});
        this.model.set('nbrPackDeliveredToPrinter', selectedModel.get('nbrPackDeliveredToPrinter'), {silent: true});
        this.model.set('nbrPackSent', selectedModel.get('nbrPackSent'), {silent: true});
        this.model.set('nbrPackDelivered', selectedModel.get('nbrPackDelivered'), {silent: true});
        this.model.set('expShippingDate', selectedModel.get('expShippingDate'), {silent: true});
        this.model.set('deadlineDate', selectedModel.get('deadlineDate'), {silent: true});
        this.deliveryView.render();
      }
    },

    renderMessages: function () {
      var messageExists = false;
      var ukrMessageExists = false;
      if (Settings.configValue('board.card.messageIcon') !== 'fromComments') {
        _.each(this.cardsView.collection.models, function (model) {
        if (model.get('actionRequiredInternal'))
          messageExists = true;
        if (model.get('actionRequiredUkr'))
          ukrMessageExists = true;
        }, this);
      }

      if (messageExists)
        this.ui.messages.show();
      else
        this.ui.messages.hide();

      if (ukrMessageExists)
        this.ui.ukrMessages.show();
      else
        this.ui.ukrMessages.hide();
    },

    renderVip: function () {
      if (this.order.get('customer') && this.order.get('customer').vip)
        this.ui.vip.css('display', 'flex');
      else
        this.ui.vip.css('display', 'none');
    },

    fetch: function () {
      this.order = this.cardsView.collection.at(0);

      var selectedDate = null;

      _.each(this.cardsView.collection.models, function (model) {
        var date = model.get('createDate');
        if (date) {
          if (typeof (date) === 'object')
            date = moment.unix(date.time / 1000);
          else
            date = moment(date);

          if (selectedDate === null || date < selectedDate)
            selectedDate = date;
        }
      }, this);

      this.model.set('createDate', selectedDate);

      //-------------------
      var price = 0;
      var buyingPrice = 0;

      _.each(this.cardsView.collection.models, function (model) {

        if(model.get('price') && _.isNumber(model.get('price')))
          price += model.get('price');
        if(model.get('buyingPrice') && _.isNumber(model.get('buyingPrice')))
          buyingPrice += model.get('buyingPrice');
      }, this);

      this.model.set('price', price);
      this.model.set('buyingPrice', buyingPrice);

      //-------------------
      this.renderName();
      this.renderDate();
      this.renderCountry();
      this.renderSameOrder();
      this.renderPrice();
      this.renderMinimized();
      this.renderPriority();
      this.renderLabels();
      this.renderUsers();
      this.renderDelivery();
      this.renderMessages();
      this.renderVip();
    },

    removeOrder: function (order) {
      this.model.get('orders').remove(order);
      this.model.trigger('change:orders');

      if(this.cardsView.collection.length === 1) {
        var lastModel = this.cardsView.collection.at(0);
        this.cardsView.collection.remove(lastModel);
        lastModel.set('position', this.model.get('position'));
        this.options.cards.collection.remove(this.model);
        this.options.cards.addOrder(lastModel);
      }

      if(this.cardsView.collection.length === 0)
        this.options.cards.collection.remove(this.model);
    },

    getOrder: function (purchaseOrderId) {
      var order = null;
      _.each(this.cardsView.collection.models, function (model) {
        if (model.get('poi') === purchaseOrderId)
          order = model;
      }, this);

      return order;
    },

    unSelectAll: function () {
      this.unSelect();
      this.cardsView.unSelectAll();
    },

    select: function () {
      this.$el.addClass('selected');
      this.cardsView.select();

      this.options.boardView.headerView.renderUnSelectButton(this.options.kanbanView.getSelectedOrders());
    },

    unSelect: function () {
      this.$el.removeClass('selected');
      this.cardsView.unSelectAll();
      this.options.boardView.headerView.renderUnSelectButton(this.options.kanbanView.getSelectedOrders());
    },

    isSelected: function () {
      return this.$el.hasClass('selected');
    },

    onSelect: function (e) {
      e.preventDefault();
      e.stopPropagation();

      this.options.kanbanView.hideSortPanels();

      if (this.$el.hasClass('selected'))
        this.unSelect();
      else {
        if (e.ctrlKey || e.altKey)  // alt instead ctrl for mac os
          this.select();
        else {
          this.options.kanbanView.unSelectAll();
          this.select();
        }
      }
    },

    changeMinimized: function (e) {
      e.stopPropagation();

      this.model.set('minimized', !this.model.get('minimized'));

      _.each(this.cardsView.collection.models, _.bind(function (model) {
        model.set('minimized', this.model.get('minimized'));
      }, this));
    },

    onMouseDown: function (e) {
      this.options.kanbanView.lastSelected = this.cardsView.collection.models[0];
      if(e.which === 3) {
        e.stopPropagation();
        if (this.$el.hasClass('selected'))
          return;
        else {
          this.options.kanbanView.unSelectAll();
          this.select();
        }
      }
    },

    openCustomer: function (e) {
      e.stopPropagation();

      if(!this.order)
        return;

      var customer = App.request('customers:entity:end-user-id', this.order.get('endUserId'));
      customer.fetch().done(_.bind(function () {
        if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_CUSTOMERS]) {
          if (Settings.configValue('orders.detail.editCustomerInNewTab')) {
            var baseURL = window.location.protocol + '//' + window.location.hostname;
            if (window.location.port) {
              baseURL += ':' + window.location.port;
            }
            var url = baseURL + '#customers/' + customer.get('secId');
            window.open(url);
          } else {
            App.navigate('customers/' + customer.get('secId'), {trigger: true});
          }
        }
      }, this));


    }
  });
});
  
