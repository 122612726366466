define('mailsMailView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'settings',
  'dateUtils',
  'moment',
  'emailView',
  'mailsMailAttachmentsView',
  'mailsMailLabelsView',
  'template!mailsMailView',
  'entities/mails'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  Settings,
  DateUtils,
  moment,
  EmailView,
  AttachmentsView,
  LabelsView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'mail-view',

    ui: {
      subject: '.subject',
      fromName: '.from .name',
      fromMail: '.from .mail',
      toName: '.to .name',
      toMail: '.to .mail',
      ccName: '.cc .name',
      ccMail: '.cc .mail',
      date: '.date',
      content: '.mail-content',
      loader: '.loader-ctn',
      mailIframe: '.mail-iframe',
      replyButton: '.reply',
      attachmentsRegion: '.attachments-region',
      customerSecId: '.customerSecId'
    },

    events: {
      'click @ui.replyButton': 'onReply'
    },

    regions: {
      commentsRegion: '.comments-region',
      attachmentsRegion: '.attachments-region',
      labelsRegion: '.labels-region'
    },

    onShow: function () {

      this.labelsView = new LabelsView({
        model: this.model,
        mailSecId: this.options.mailSecId
      });
      this.getRegion('labelsRegion').show(this.labelsView);

      //--------------------
      this.fetch();
    },

    fetch: function () {
      var defer = $.Deferred();

      if (this.fetchRequest)
        this.fetchRequest.abort();

      this.ui.loader.css('display', 'flex');
      this.ui.content.hide();
      this.$el.addClass('open');

      this.fetchRequest = $.ajax({
        type: 'GET',
        url: App.request('mails:get-mail-url', this.options.mailSecId, this.model.get('folder').fullName, this.model.get('id')),
        success: _.bind(function (item) {
          this.model = App.request('mails:model', item);
          this.renderContent();
          this.ui.loader.hide();
          this.ui.content.css('display', 'flex');
          defer.resolve();

          this.renderAttachments();
        }, this)
      });

      return defer.promise();
    },

    renderContent: function () {
      var content = this.model.get('content');
      _.each(this.model.get('attachments'), function (attachment) {
        if (attachment.inline === true) {
          if (attachment.contentId) {
            var contentId = 'cid:' + attachment.contentId.slice(1, -1);
            var replacement = 'data:' + attachment.contentType + ';base64,' + attachment.base64;
            var regex = new RegExp(contentId, 'g');
            content = content.replace(regex, replacement.replace(/"/g, '\''));
          }
        }
      }, this);

      if(this.ui.mailIframe && this.ui.mailIframe.length > 0) {
        try {
          var iframeDoc = this.ui.mailIframe.get(0).contentWindow.document;
          iframeDoc.open();
          iframeDoc.write(content);
          iframeDoc.close();
        } catch (e) {
          // ignore
        }
      }

      //------------------------
      if (this.model.get('from') && this.model.get('from').length > 0) {
        if (this.model.get('from')[0].personal) {
          this.ui.fromName.html(this.model.get('from')[0].personal);
          this.ui.fromName.css('display', 'flex');
        } else {
          this.ui.fromName.hide();
        }
        this.ui.fromMail.html('&lt;' + this.model.get('from')[0].address + '&gt;');
      }
      if (this.model.get('to') && this.model.get('to').length > 0) {
        if (this.model.get('to')[0].personal) {
          this.ui.toName.html(this.model.get('to')[0].personal);
          this.ui.toName.css('display', 'flex');
        } else {
          this.ui.toName.hide();
        }
        this.ui.toMail.html('&lt;' + this.model.get('to')[0].address + '&gt;');
      }
      if (this.model.get('cc') && this.model.get('cc').length > 0) {
        if (this.model.get('cc')[0].personal) {
          this.ui.ccName.html(this.model.get('cc')[0].personal);
          this.ui.ccName.css('display', 'flex');
        } else {
          this.ui.ccName.hide();
        }
        this.ui.ccMail.html('&lt;' + this.model.get('cc')[0].address + '&gt;');
      }

      this.ui.subject.html(this.model.get('subject'));

      this.ui.date.html(DateUtils.toMDYHHMMFormat(this.model.get('sentDate')));

      var customerSecId = this.model.get('customerSecId');
      if (customerSecId) {
        this.ui.customerSecId.css('display', 'flex');
        this.ui.customerSecId.attr('href', '#/customers/' + this.model.get('customerSecId') + '/profile');
      }
    },

    renderAttachments: function () {
      var collection = new Backbone.Collection();

      _.each(this.model.get('attachments'), function (attachment) {
        collection.add(attachment);
      }, this);

      if (collection.models.length === 0) {
        this.ui.attachmentsRegion.hide();
        return;
      }

      this.attachmentsView = new AttachmentsView({
        collection: collection,
        model: this.model,
        parentView: this,
        mailSecId: this.options.mailSecId
      });
      this.getRegion('attachmentsRegion').show(this.attachmentsView);
      this.ui.attachmentsRegion.css('display', 'flex');
    },

    onReply: function () {
      var from = null;
      _.each(this.options.parentView.options.parentView.options.parentView.menuView.mailsCollection.models,
          function (model) {
        if (model.get('id') === this.options.mailSecId)
          from = model.get('value');
      }, this);

      if (!from)
        return;

      var to = null;
      var cc = null;

      if (this.model.get('from') && this.model.get('from').length > 0)
        to = this.model.get('from')[0].address;
      if (this.model.get('cc') && this.model.get('cc').length > 0)
        cc = this.model.get('cc')[0].address;

      if (!to)
        return;

      var content = '<br><br><br>';
      content += DateUtils.toMDYHHMMFormat(this.model.get('sentDate'));
      //content += moment(this.model.get('sentDate')).format('ddd, MMM D, YYYY [at] h:mmA');
      content += ' &lt;' + to + '&gt; wrote:<br><br>';
      content += this.model.get('content');

      EmailView.prototype.open({
        from: from,
        to: to,
        cc: cc,
        content: content,
        subject: 'Re: ' + this.model.get('subject'),
        allowFromSelection: true,
        title: '',
        id: 'new-mail',
        templateTypes: ['MAIL_SIGN'],
        defaultTemplate: 'MAIL_SIGN',
        templateData: {
          user: Settings.get('currentUser'),
          customer: { }
        },
        templateLocale: Settings.get('lang')
      });
    }
  });
});

