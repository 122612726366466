define('tableFooterView',[
  'module',
  'backbone',
  'marionette',
  'underscore',
  'tableFooterPageView',
  'template!tableFooterView'
], function(
  Module,
  Backbone,
  Marionette,
  _,
  PageView,
  Tpl
) {
  'use strict';
  
  Module.exports = Marionette.CompositeView.extend({
    template: Tpl,       
    className: 'table-footer-view',
    childView: PageView,
    childViewContainer: '.pages-container',

    childViewOptions: function() {
      return {
        tableView: this.options.view,
        footerView: this
      };
    },

    ui: {
      paginator: '.paginator',
      first: '.first',
      prev: '.prev',
      next: '.next',
      last: '.last',
      infos: '.infos',
      loader: '.loader-2',
      reloadButton: '.reload',
      sortButton: '.sort',
      pageSizeContainer: '.page-size',
      pageSize: '.page-size input'
    },

    events: {
      'click @ui.first': 'first',
      'click @ui.prev': 'prev',
      'click @ui.next': 'next',
      'click @ui.last': 'last',
      'click @ui.reloadButton': 'reload',
      'click @ui.sortButton': 'sort',
      'change @ui.pageSize': 'changePageSize'
    },

    initialize: function() {
      this.collection = new Backbone.Collection();
      this.tableView = this.options.view;
      this.bodyView = this.tableView.bodyView;
      this.currentPage = 1;
      this.pageCount = 0;
      this.maxPages = this.tableView.footerMaxPages;
    },

    onShow: function() {
      this.fetch();
    },

    onRender: function() {
      this.renderSortButton();
    },

    setCurrentPage: function(page) {
      if(page < 1)
        page = 1;
      if(page > 1 && page > this.pageCount)
        page = this.pageCount;

      if(page === this.currentPage)
        return;

      this.currentPage = page;
      this.bodyView.first = this.tableView.pageSize * (this.currentPage - 1);
      this.bodyView.fetch();
      this.renderContent();
      this.renderInfos();
    },

    fetch: function() {
      this.options.fetch(this.tableView.filters)
      .done(_.bind(function (count) {
        this.count = count;
        this.pageCount = Math.ceil(this.count / this.tableView.pageSize);
        this.renderContent();
        this.renderInfos();
      }, this));
    },

    renderContent: function() {
      this.collection.reset();

      var first = 1;

      if(this.currentPage > Math.ceil(this.maxPages/2))
        first = this.currentPage - Math.ceil(this.maxPages/2);

      var last = first + this.maxPages - 1;

      if(last > this.pageCount)
        last = this.pageCount;

      for (var i = first; i <= last; i++)
        this.collection.add({page: i});

      //----------
      if(this.ui.paginator) {
        if (this.pageCount >= 1 && this.tableView.showPaginator)
          this.ui.paginator.css('display', 'flex');
        else
          this.ui.paginator.hide();
      }
      //----------
      if (this.tableView.showPageSizeSeletor)
        this.ui.pageSizeContainer.css('display', 'flex');
      else
        this.ui.pageSizeContainer.hide();

      //----------
      this.ui.pageSize.val(this.tableView.pageSize);
    },

    renderInfos: function() {
      if(this.pageCount >= 1)
        this.ui.infos.css('display', 'flex');
      else
        this.ui.infos.hide();

      var last = this.bodyView.first + this.tableView.pageSize;
      if(last > this.count)
        last = this.count;

      this.ui.infos.html(this.bodyView.first + ' - ' + last + ' / ' + this.count);
    },

    renderSortButton: function() {
      if(this.tableView.sortable) {
        if(this.tableView.sortActivated)
          this.ui.sortButton.addClass('active');
        else
          this.ui.sortButton.removeClass('active');
        this.ui.sortButton.css('display', 'flex');
      }

      else
        this.ui.sortButton.hide();
    },

    first: function() {
      this.setCurrentPage(1);
    },

    prev: function() {
      this.setCurrentPage(this.currentPage - 1);
    },

    next: function() {
      this.setCurrentPage(this.currentPage + 1);
    },

    last: function() {
      this.setCurrentPage(this.pageCount);
    },

    reload: function() {
      this.bodyView.fetch();
      this.fetch();
    },

    sort: function() {
      if(this.tableView.sortActivated) {
        this.tableView.bodyView.$el.sortable('disable');

        if(this.tableView.filtersView)
          this.tableView.filtersView.$el.css('display', 'flex');
        this.tableView.filtersActivated = true;
      }
      else {
        if(this.tableView.filtersView)
          this.tableView.filtersView.$el.hide();
        this.tableView.filtersActivated = false;
      }

      this.currentPage = 1;
      this.bodyView.first = this.tableView.pageSize * (this.currentPage - 1);
      this.bodyView.fetch().done(_.bind(function() {
        this.tableView.sortActivated = !this.tableView.sortActivated;
        this.renderSortButton();
          if(this.tableView.sortActivated)
            this.tableView.bodyView.$el.sortable('enable');
          else
            this.tableView.bodyView.$el.sortable('disable');
      }, this));
      this.fetch();
    },

    loader: function(show) {
      if(this.isDestroyed)
        return;

      if(show)
        this.ui.loader.css('display', 'flex');
      else
        this.ui.loader.hide();
    },

    changePageSize: _.debounce(function() {
      this.tableView.pageSize = parseInt(this.ui.pageSize.val(), 10);
      this.currentPage = 1;
      this.bodyView.first = this.tableView.pageSize * (this.currentPage - 1);
      this.bodyView.fetch();
      this.fetch();
      console.log('changePageSize');
    }, 1000)
  });  
});

