
define('template!mailsListMailView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="top ' +
((__t = ( unreadStatus )) == null ? '' : __t) +
'">\n  <div class="left">\n    <div class="name">' +
((__t = ( name )) == null ? '' : __t) +
'</div>\n    <div class="subject">' +
((__t = ( subject )) == null ? '' : __t) +
'</div>\n  </div>\n  <div class="right">\n    ';
 if(hasAttachments) { ;
__p += '\n      <div class="attachments">\n        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M364.2 83.8c-24.4-24.4-64-24.4-88.4 0l-184 184c-42.1 42.1-42.1 110.3 0 152.4s110.3 42.1 152.4 0l152-152c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-152 152c-64 64-167.6 64-231.6 0s-64-167.6 0-231.6l184-184c46.3-46.3 121.3-46.3 167.6 0s46.3 121.3 0 167.6l-176 176c-28.6 28.6-75 28.6-103.6 0s-28.6-75 0-103.6l144-144c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-144 144c-6.7 6.7-6.7 17.7 0 24.4s17.7 6.7 24.4 0l176-176c24.4-24.4 24.4-64 0-88.4z"/></svg>\n      </div>\n    ';
 } ;
__p += '\n    <div class="date">' +
((__t = ( date )) == null ? '' : __t) +
'</div>\n  </div>\n</div>\n<div class="labels-region"></div>';

}
return __p
};});

