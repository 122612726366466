
define('template!webshopCategoriesDetailBodyProductsHeaderToolsView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="import-region"></div>\n<div class="export-position-region"></div>\n<div class="add-region"></div>\n<input type="file" class=\'upload-input\' multiple="single">';

}
return __p
};});

