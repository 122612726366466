define('mailsListMailView',[
  'app',
  'module',
  'backbone',
  'tableFieldView',
  'dateUtils',
  'underscore',
  'mailsListMailLabelsView',
  'template!mailsListMailView',
  'notificationCenter'
], function (
  App,
  Module,
  Backbone,
  FieldView,
  DateUtils,
  _,
  LabelsView,
  Tpl,
  NotificationCenter
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'mail-view ' + FieldView.prototype.className,

    regions: {
      labelsRegion: '.labels-region'
    },

    initialize: function() {
      NotificationCenter.getInstance().on(
          NotificationCenter.events.EMAILS_GMAIL_API_MESSAGE_LABELS_ADDED, _.bind(function (response) {
            if (response && response.content && response.content.content && response.content.content.text) {
              if(response.content.content.entitySecId === this.model.get('id')) {
                // Mark UNREAD if label was added
                if(response.content.content.text === 'UNREAD') {
                  this.model.set('read', false);
                  this.render();
                }
              }
            }
          }, this),
          this
      );
      NotificationCenter.getInstance().on(
          NotificationCenter.events.EMAILS_GMAIL_API_MESSAGE_LABELS_REMOVED, _.bind(function (response) {
            if (response && response.content && response.content.content && response.content.content.text) {
              if(response.content.content.entitySecId === this.model.get('id')) {
                // Mark READ if label was removed
                if(response.content.content.text === 'UNREAD') {
                  this.model.set('read', true);
                  this.render();
                }
              }
            }
          }, this),
          this
      );
    },

    attributes: function() {
      var attributes = {};

      if(this.model.get('attachments') && this.model.get('attachments').length > 0)
        attributes['data-attachments'] = true;
      else
        attributes['data-attachments'] = false;

      return attributes;
    },

    serializeData: function() {
      var data = {};
      data.unreadStatus = this.model.get('read')?'read':'unread';
      data.mail = this.model.getAddress();
      data.name = this.model.getName();
      data.subject = this.model.get('subject');

      data.date = DateUtils.toMDYHHMMFormat(this.model.get('sentDate'));

      data.hasAttachments = false;
      if(this.model.get('hasAttachments'))
        data.hasAttachments = true;

      return data;
    },

    onRender: function() {
      this.labelsView = new LabelsView({
        model: this.model
      });
      this.getRegion('labelsRegion').show(this.labelsView);
    }
  });
});
