define('ordersDetailOrderClientImageView',[
  'module',
  'marionette',
  'underscore',
  'template!ordersDetailOrderClientImageView',
  'pluginUtils'
], function(
  Module,
  Marionette,
  _,
  Tpl,
  PluginUtils
) {
  'use strict';

  Module.exports = Marionette.ItemView.extend({    
    className: 'image-view',
    template: Tpl,  

    ui: {    
      image: 'img',
      btnPlacePA: '.js-placePA'
    },
    events: {
        'click @ui.btnPlacePA': 'onPlacePA'
    },

    serializeData: function() {
      var data = this.model.toJSON();
      if (!data.imageUid) {
        data.imageUid = null;
      }
      return data;
    },

    onRender: function() {
      this.ui.image.attr('src', this.model.get('url'));

      this.$el.hide();
      //this.$el.attr('data-index', this.model.get('index'));     
    },

    show: function() {
      this.$el.show();
    },

    hide: function() {
      this.$el.hide();
    },

    onPlacePA: function(e) {
      // Prevent the click to go through, triggering the image zoom pop-up
      e.preventDefault();
      e.stopPropagation();

      // The clicked icon has got required data as attributes.
      PluginUtils.callPlugin({
        action: 'placePimPrintArea',
        productId: e.currentTarget.getAttribute('data-productid'),
        variationId: e.currentTarget.getAttribute('data-variationid'),
        imageUid: e.currentTarget.getAttribute('data-uid')
      });
    }
  });   
});
