/**
 * Created by BFR on 29/03/2023.
 */
define('editPimVariationView',[
  'module',
  'marionette',
  'app',
  'settings',
  'underscore',
  'template!editPimVariationTpl',
  'editPimOptionsView',
  'pluginUtils'
], function (
  module,
  Marionette,
  App,
  Settings,
  _,
  viewTpl,
  EditPimOptionsView,
  PluginUtils
) {
  'use strict';

  var EditPimVariationView = Marionette.LayoutView.extend({
    template: viewTpl,
    className: 'var-item',

    modelEvents: {
      sync: 'render'
    },

    events: {
      'mouseover': 'onMouseOver',
      'mouseout': 'onMouseOut',
      'change .js-upload-variation-image': 'onUploadVariationImage',
      'click .js-placePrintArea': 'onPlacePrintArea'
    },

    ui: {
      btnContainer: '.btn-variation-container'
    },

    regions: {
      editOptions: '.options-region'
    },

    onShow: function () {
      var that = this;

      // Add context menu on variation images
      this.$el.contextMenu({
        selector: '.context-menu-option-variation-add-images',
        callback: function () {
        },
        items: {
          image: {
            name: 'Delete variation image',
            callback: function () {
              // Fade away. Upon error, render() will put it back visible
              this.attr('class', this.attr('class') + ' fade');
              // Delete image by API call
              that.deleteImage(
                  this.find('img').attr('data-productId'),
                  this.find('img').attr('data-variationId'),
                  this.find('img').attr('data-uid')
              );
            }
          },
          placePrintArea: {
            name: 'Place print area',
            callback: function () {
              that.onPlacePrintArea(
                  this.find('img').attr('data-productid'),
                  this.find('img').attr('data-variationid'),
                  this.find('img').attr('data-uid')
              );
            }
          }
        },
        trigger: 'right'
      });

    },

    onRender: function () {
      this.editOptionsView = new EditPimOptionsView(
        {
          collection: this.model.get('productOptions'),
          editable: false,
          enableOnOff: false
        });
      this.getRegion('editOptions').show(this.editOptionsView);

      _.each(this.$el.find('.var-addImages .image'), _.bind(function (image) {
        var content = '<div class="img-responsive"><img class="img-popover" src="' + $(image).find('img').attr('src') + '"/></div>';

        var target = $(image).find('img');

        target.attr('data-content', content);
        target.popover({
          trigger: 'hover',
          placement: 'right',
          html: 'true',
          container: $('#content')
        }).data('bs.popover').tip().addClass('jqgrid-popover');
      }, this));
    },

    onMouseOver: function () {
      this.ui.btnContainer.show();
    },

    onMouseOut: function () {
      this.ui.btnContainer.hide();
    },

    serializeData: function () {
      var templateData = this.model.toJSON();

      templateData.productId = '';
      if (this.options.pimProductId) {
        templateData.productId = this.options.pimProductId;
      }

      templateData.code = '';
      if (this.model.get('code')) {
        templateData.code = this.model.get('code');
      }
      templateData.name = '';
      if (this.model.get('name')) {
        templateData.name = this.model.get('name').getTextInLocale(Settings.get('lang'));
      }

      // Activated true by default, unless specified otherwise in resellerData.
      templateData.activated = true;
      try {
        templateData.optionCode = this.model.get('productOptions').models[0].get('code');
        var currentModel = this.options.model.get('productOptions').models.at(0).get('pimId');
        // If reseller data has an overridden value, use that.
        if (this.options.pimProduct && this.options.pimProduct.get('resellerData')) {
          _.each(this.options.pimProduct.get('resellerData').models, _.bind(
                  function (rd) {
                    if (rd && rd.get('pimPoResellers')) {
                      _.each(rd.get('pimPoResellers').models, _.bind(
                          function (poc) {
                            if (poc && poc.get('productOptions')) {
                              _.each(poc.get('productOptions').models, _.bind(
                                  function (po) {
                                    // Apply the activated value from the reseller data if the pimId is matching
                                    if (po && po.get('pimId') === currentModel) {
                                      if (po.get('activated') !== undefined) {
                                        templateData.activated = po.get('activated');
                                      }
                                    }
                                  }
                              ));
                            }
                          }
                      ));
                    }
                  }
              )
          );
        }
      } catch (e) {
          console.log('Error in EditPimVariationView. Could not apply any override from pimPoResellers', e);
      }

      // Extract variation images from overlay
      templateData.images = [];
      if(this.options.pimSupplierData && this.options.pimSupplierData.pimSupplierVariations) {
        _.each(this.options.pimSupplierData.pimSupplierVariations, function(pimSupplierVariation) {
          if(pimSupplierVariation.code === templateData.code) {
            templateData.images = pimSupplierVariation.addImages;
          }
        });
      }

      return templateData;
    },

    onUploadVariationImage: function (e) {
      var productId = this.options.pimProductId;
      var variationId = this.model.get('code');
      var that = this;
      var formData = new FormData();

      if (!e.target.files) {
        console.error('No file selected for upload');
        return;
      }

      try {
        // Loop through all the files and add them to the formData object
        for (var i = 0; i < e.target.files.length; i++) {
            formData.append('file', e.target.files[i]);
        }
      } catch (perror) {
        console.error('Error uploading image', perror);
        return;
      }

      //console.log('Uploading image for ', productId, variationId);
      // Make API request to upload the image
      var uploadRequest = App.request('pim:products:upload-variation-image', productId, variationId, formData);
      $.when(uploadRequest)
          .done(_.bind(function (response) {
            //console.log('Image(s) uploaded successfully');
            // The response contains the refreshed list of pimSupplierData.pimSupplierVariations
            that.model.get('productOptions').models.at(0).set('addImages', null);
            that.options.pimSupplierData = response;

            // Refresh the view
            that.render();
          }, this))
          .fail(_.bind(function () {
            console.error('Error uploading image.');
          }, this));
  },

  deleteImage: function (productId, variationId, uid) {
    //console.log('Delete image', productId, variationId, uid);
    var that = this;

    var deleteImageVariationRequest = App.request('pim:products:delete-variation-image', productId, variationId, uid);
    $.when(deleteImageVariationRequest)
        .done(_.bind(function (response) {
          //console.log('Image(s) deleted successfully');
          // The response contains the refreshed list of pimSupplierData.pimSupplierVariations
          that.model.get('productOptions').models.at(0).set('addImages', null);
          that.options.pimSupplierData = response;

          // Refresh the view
          that.render();
        }, this))
        .fail(_.bind(function () {
          console.error('Error deleting image.');
          // Refresh the view
          that.render();
        }, this));
  },

  onPlacePrintArea: function (productId, variationId, uid) {

    PluginUtils.callPlugin({
      action: 'placePimPrintArea',
      productId: productId,
      variationId: variationId,
      imageUid: uid
    });

    /**
     * This will trigger the following request to open the Flex plugin:
     * GET http://pluginair.lan:52005/executeAction?
     *     action=placePimPrintArea&
     *     productId={{productId}}&
     *     variationId={{variationId}}&
     *     imageUid={{imgUid}}&
     *     tokenId={{tokenId}}&
     *     lang=en&
     *     isAdmin=true
     */

  }

  });

  module.exports = EditPimVariationView;
});
