define('webshopProductsListUnfinishedView',[
  'app',
  'module',
  'backbone',
  'underscore',
  'settings',
  'checkboxView',
  'tableFieldView',
  'template!webshopProductsListUnfinishedView',
  'entities/webshop/products'
], function (
  App,
  Module,
  Backbone,
  _,
  Settings,
  CheckboxView,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'webshop-products-list-unfinished-view ' + FieldView.prototype.className,

    regions: {
      'checkboxRegion': '.checkbox-region'
    },

    modelEvents: {
      'change:unfinished': 'render'
    },

    onRender: function () {
      this.checkboxView = new CheckboxView({
        title: _.i18n('webshop.product.unfinished.title'),
        text: _.i18n('webshop.product.unfinished'),
        value: this.model.getUnfinished(),
        onChange: _.bind(function (value) {
          var defer = $.Deferred();
          this.model.setUnfinished(value).done(_.bind(function () {
            this.model.fetch().done(_.bind(function () {
              this.checkboxView.setValue(this.model.getUnfinished());
              defer.resolve();
            }, this));
          }, this)).fail(_.bind(function () {
            this.model.fetch();
            defer.reject();
          }, this));
          return defer.promise();
        }, this)
      });
      this.checkboxRegion.show(this.checkboxView);
    }
  });
});
