define('webshopProductsListView',[
  'module',
  'underscore',
  'app',
  'backbone',
  'settings',
  'windows',
  'tableView',
  'tableDatetimeFieldView',
  'webshopProductsListImageView',
  'webshopProductsListInfosView',
  'webshopProductsListSupplierView',
  'webshopProductsListPriceView',
  'webshopProductsListStatusView',
  'webshopProductsListCategoryView',
  'webshopProductsListTagsView',
  'webshopProductsListOutdatedView',
  'webshopProductsListUnfinishedView',
  'webshopProductsListFiltersView',
  'webshopProductsListToolsView',
  'webshopProductsDetailView',
  'entities/webshop/products',
  'entities/webshop/categories'
], function (
  Module,
  _,
  App,
  Backbone,
  Settings,
  Windows,
  TableView,
  DatetimeFieldView,
  ImageView,
  InfosView,
  SupplierView,
  PricesView,
  StatusView,
  CategoryView,
  TagsView,
  OutdatedView,
  UnfinishedView,
  FiltersView,
  ToolsView,
  DetailView
) {
  'use strict';

  Module.exports = TableView.extend({
    className: 'webshop-products-list-view ' + TableView.prototype.className,
    pageSize: 50,
    pageMode: 'pagination',
    filtersViewClass: FiltersView,

    init: function() {
      App.request('webshop.categories.datalist');
    },

    setFields() {
      var fields = [];

      fields.push({
        name: 'Image',
        className: 'image',
        view: ImageView
      });

      fields.push({
        name: 'Date',
        label: _.i18n('common.date'),
        bind: 'createdAt',
        className: 'date',
        view: DatetimeFieldView
      });

      fields.push({
        name: 'Infos',
        label: _.i18n('common.product'),
        className: 'infos',
        view: InfosView
      });

      fields.push({
        name: 'Supplier',
        label: _.i18n('product.supplier'),
        className: 'supplier',
        view: SupplierView
      });

      /*fields.push({
        name: 'Prices',
        label: _.i18n('common.price'),
        className: 'prices',
        view: PricesView
      });*/

      /*if(Settings.configValue('webshop.products.showOutdated')) {
        fields.push({
          name: 'Outdated',
          label: _.i18n('webshop.product.outdated'),
          className: 'outdated',
          view: OutdatedView
        });
      }*/

      if (Settings.configValue('webshop.products.showUnfinished')) {
        fields.push({
          name: 'Unfinished',
          label: _.i18n('webshop.product.unfinished'),
          className: 'unfinished',
          view: UnfinishedView
        });
      }

      fields.push({
        name: 'Tags',
        label: _.i18n('webshop.product.tag'),
        className: 'tags',
        view: TagsView
      });

      if (Settings.configValue('webshop.products.showDefaultCategory')) {
        fields.push({
          name: 'Category',
          label: _.i18n('common.category'),
          className: 'category',
          view: CategoryView
        });
      }

      fields.push({
        name: 'Status',
        label: _.i18n('common.status'),
        className: 'status',
        view: StatusView
      });

      fields.push({
        name: 'Tools',
        label: '',
        className: 'tools',
        view: ToolsView
      });

      return fields;
    },

    fetch: function(first, pageSize, sortField, sortDirection, filters) {
      var defer = $.Deferred();

      var params = this.getFiltersParams(filters);
      params.first = first;
      params.pageSize = pageSize;

      var promises = [];

      if (Settings.configValue('webshop.products.showDefaultCategory'))
        promises.push(App.request('webshop.categories.datalist'));
      promises.push(App.request('webshop.products.tags'));

      $.when.apply($, promises).done(_.bind(function () {
        if (this.fetchRequest)
          this.fetchRequest.abort();

        this.fetchRequest = $.ajax({
          type: 'GET',
          url: App.request('webshop.products.search', params),
          success: _.bind(function (products) {
            var models = [];

            _.each(products, _.bind(function (item) {
              var model = App.request('webshop.product.model', item);
              models.push(model);
            }, this));

            defer.resolve(models);
          }, this)
        });
      }, this));

      return defer.promise();
    },

    fetchCount: function(filters) {
      var defer = $.Deferred();

      var params = this.getFiltersParams(filters);

      if(this.fetchCountRequest)
        this.fetchCountRequest.abort();

      this.fetchCountRequest = $.ajax({
        type: 'GET',
        url: App.request('webshop.products.count', params),
        success: _.bind(function (count) {
          defer.resolve(count);
        }, this)
      });

      return defer.promise();
    },

    getFiltersParams: function(filters) {
      var params = {};

      _.each(filters.attributes, function (value, key) {
        if(key !== 'dateFrom' &&
            key !== 'dateTo') {
          if(value !== '')
            params[key] = value;
        }
      }, this);

      if(filters.get('dateFrom') !== '' && filters.get('dateTo') !== '') {
        params.dateFrom = filters.get('dateFrom');
        params.dateTo = filters.get('dateTo');
      }

      return params;
    },

    onRowDoubleClick: function(view) {
      Windows.open({
        view: _.bind(function () {
          return new DetailView({
            code: view.model.getCode()
          });
        }, this),
        id: 'webshop.product:' + view.model.get('code'),
        title: _.i18n('webshop.product.title'),
        width: '80%',
        height: '80%'
      });
    }
  });
});
