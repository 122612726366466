define('webshopProductsDetailHeaderView',[
  'module',
  'backbone',
  'marionette',
  'underscore',
  'dateUtils',
  'galleryView',
  'template!webshopProductsDetailHeaderView'
], function (
  Module,
  Backbone,
  Marionette,
  _,
  DateUtils,
  GalleryView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'webshop-products-detail-header-view',

    ui: {
      imageContainer: '.left .image',
      image: '.left .image img',
      name: '.top > .name',
      code: '.bottom > .code',
      supplierCode: '.bottom > .supplier > .code',
      supplierName: '.bottom > .supplier > .name',
      createdAt: '.created-at .date',
      updatedAt: '.updated-at .date'
    },

    modelEvents: {
      'change:snapshotsUids': 'renderImage',
      'change:texts': 'renderContent',
      'change': 'renderContent'
    },

    events: {
      'click @ui.imageContainer': 'onClick'
    },

    serializeData: function() {
      var data = {};
      data.src = this.model.getPreviewImageUrl();
      return data;
    },

    onRender: function () {
      this.renderImage();
      this.renderContent();
    },

    renderImage: function () {
      this.ui.image.attr('src', this.model.getPreviewImageUrl());
    },

    renderContent: function () {
      this.ui.name.html(this.model.getName());
      this.ui.code.html(this.model.getCode());
      this.ui.createdAt.html(DateUtils.toMDYHHMMFormat(this.model.get('createdAt')));
      this.ui.updatedAt.html(DateUtils.toMDYHHMMFormat(this.model.get('updatedAt')));

      this.ui.supplierCode.html(this.model.getSupplierCode());
        this.ui.supplierName.html(this.model.getSupplierName());
    },

    onClick: function () {
      var urls = this.model.getImageUrls();
      if(urls.length === 0)
        return;

      GalleryView.prototype.open(
          'webshop.product.gallery.' + this.model.getCode(),
          _.i18n('webshop.product.gallery.title') + ' | ' + this.model.getName(),
          new Backbone.Model({urls: [this.model.getImageUrls()]}));
    }
  });
});
